import React from 'react';

import Context from './context'

import general from './general.png'
import traffic from './traffic.png'

const applyData = [
  {
    key: 'general',
    src: general,
    text: '一般案件聲請',
  },
  {
    key: 'traffic',
    src: traffic,
    text: '交通案件聲請',
  },
]

const applyByKey = applyData.reduce((all, d) => {
  all[d.key] = d
  return all
}, {})

const Provider = ({ children }) => {
  return (
    <Context.Provider value={{ applyData, applyByKey }}>
      {children}
    </Context.Provider>
  )
}

export default Provider;
