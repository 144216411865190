import React, { useContext, useMemo } from 'react'

import applyContext from 'contexts/apply/context'
import ApplyProvider from 'contexts/apply/Provider'

import Thanks from '../Thanks'

const ClaimThanks = ({ pageContext: { id }, ...props }) => {
  const { applyByKey } = useContext(applyContext)
  const number = useMemo(() => {
    if (typeof document === 'undefined') return null
    const params = new URLSearchParams(document.location.search);
    return params.get("no")
  }, [])
  return (
    <Thanks number={number} applyByKey={applyByKey} id={id} {...props} />
  )
}

export default props => (
  <ApplyProvider>
    <ClaimThanks {...props} />
  </ApplyProvider>
)
